
.make-box{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .make-box-header{
    text-align: right;
    padding-right: 40px;
  }
  .jd-enter-scrollbar{
    height: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .jd-business-box{
      width: 720px;
      box-shadow: 0px 0px 16px 0px rgba(43, 43, 43, 0.09);
      margin: 90px auto;
      display: flex;
      flex-direction: column;
      padding: 40px 30px 40px 40px;
      box-sizing: border-box;
      .jd-business-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        .header-img{
          width: 182px;
          height: 56px;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .header-title{
          font-size: 20px;
          color: #333;
          line-height: 1;
          padding: 40px 0 24px;
          font-weight: 500;
        }
      }
      .jd-business-explain, .jd-business-request{
        display: flex;
        flex-direction: column;
        span{
          line-height: 36px;
          font-size: 16px;
        }
        &.jd-business-request{
          margin-top: 40px;
        }
      }
      .jd-business-footer{
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}
::v-deep .custom-dialog{
  .dialog-content{
    display: flex;
    flex-direction: column;
    span{
      font-size: 14px;
      color: #333;
      line-height: 24px;
      margin-top: 8px;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }
  .dialog-footer{
    margin-top: 26px;
  }
}
